import React, { Component } from 'react';
import { connect } from 'react-redux';
import { filter, map, keyBy, get, difference, keys, find } from 'lodash';

import { closePopup } from '../actions/popup';
import { createFetchProduct } from '../actions/product';
import { BASE_ZINDEX } from '../popup-factory';

import SelectSku from './SelectSku';
import { loadProductSkus } from '../helpers/ps_sku';
import Loading from './Loading';

export class SelectBreakdownSkuPopup extends Component {

  constructor(props) {
    super(props);

    const product_sku = find(get(props.product, 'skus', []), {product_sku_id: props.product_sku_id});

    this.state = {
      skus: product_sku ? [product_sku.sku]: [],
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { product, loadProduct } = this.props;
    if (!product) {
      loadProduct();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.product_id !== nextProps.product_id) {
      if (!nextProps.product) {
        nextProps.loadProduct();
      } else {
        this.fetchParts();
        this.reloadInventory();
      }
    }
  }

  async loadProductSkuIds(skus) {
    const result = await this.loadProductSkus(skus);
    return map(result, (sku) => get(sku, ['product_sku_id']));
  }

  loadProductSkus(skus) {
    const { product, loadProduct } = this.props;
    return loadProductSkus(product, loadProduct, skus);
  }

  async handleAdd(e) {
    e.preventDefault();
    const { skus } = this.state;
    if (skus.length) {
      const product_sku_ids = await this.loadProductSkuIds(skus);
      product_sku_ids.forEach(product_sku_id => {
        this.props.onAdd(product_sku_id);
      });
      this.props.onClosePopup();
    }
  }

  async handleUpdate(e) {
    e.preventDefault();
    const { skus } = this.state;
    if (skus.length) {
      const product_sku_ids = await this.loadProductSkuIds(skus);
      this.props.onUpdate(product_sku_ids[0]);
      this.props.onClosePopup();
    }
  }

  render() {
    const { product, product_id, product_sku_id, onAdd, index, onClosePopup, title = null, message = null } = this.props;
    const { skus } = this.state;
    if (!product) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loading />
        </div>
      );
    }

    return (
      <div className="reveal large" style={{ overflow: 'visible', display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial' }} role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3>{!!title ? title : (!!onAdd ? 'Add Breakdown' : 'Select SKU')}</h3>
            <p>{!!message ? message : <span>Please select the SKU{!!onAdd && 's'} you wish to {!!onAdd ? 'add' : 'use'}.</span>}</p>
          </div>
        </div>
        <div className="row full-width">
          <SelectSku
            product_id={product_id}
            product={product}
            currency_id="USD"
            onSkusSelected={skus => this.setState({ skus })}
            value={skus}
            multi={!!onAdd}
          />
          <button className="button" style={{ margin: 0, clear: 'both', float: 'right' }} disabled={skus.length === 0} onClick={!!onAdd ? this.handleAdd : this.handleUpdate}>{!!onAdd ? 'Add' : 'Update'}</button>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  product: ownProps.product || state.entities.products[ownProps.product_id]
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  loadProduct: (data) => dispatch(createFetchProduct(ownProps.product_id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectBreakdownSkuPopup);
